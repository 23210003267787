window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});

window.addEventListener("DOMContentLoaded", (event) => {
  var telButton = document.getElementById("button-a1b50cca");
  var aButton = document.createElement("a");
  aButton.setAttribute("href", "tel:844-334-5359");
  aButton.setAttribute("target", "_blank")
  telButton.parentElement.appendChild(aButton);
  aButton.appendChild(telButton);
  telButton.removeAttribute("onclick");
  var fileref = document.createElement("script");
  fileref.setAttribute("type", "text/javascript");
  fileref.setAttribute(
    "src",
    "//cdn.callrail.com/companies/339009950/03dc13dc8435da54bb2b/12/swap.js"
  );
  var head = document.getElementsByTagName("head")[0];
  head.appendChild(fileref);
});

window.addEventListener("heyflow-submit", (event) => {
  // console.log('heyflow submit:', event.detail);
});